import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTwitter,
  faLinkedin,
  faGithub,
} from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import "./footer.scss"
function Footer() {
  return (
    <footer>
      <div className="social-links">
        <div className="circle-icon">
          <a
            href="https://twitter.com/ShehbazJafri"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>
        </div>
        <div className="circle-icon">
          <a
            href="https://github.com/shehbazjafri/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FontAwesomeIcon icon={faGithub} />
          </a>
        </div>
        <div className="circle-icon">
          <a
            href="https://www.linkedin.com/in/shehbaz-jafri"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </div>
        <div className="circle-icon">
          <a
            href="mailto:hey@shehbazjafri.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
        </div>
      </div>
      <div>© {new Date().getFullYear()} Shehbaz Jafri</div>
    </footer>
  )
}

export default Footer
